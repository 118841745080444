.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.template-modal-conatiner .modal-content {
  min-width: 1080px;
  min-height: 600px;
  height: 600px;
}
.templates-modal {
  padding: 20px;
}

.templates-search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
}
.searchbale-select-container-main-container {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.searchbale-select-container {
  width: 100%;
  padding: 0px 10px;
}

.searchbale-select-container-options {
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
}

.border-none {
  border: none;
  outline: none;
}
