.ticket-details-main-container {
  padding: 16px;
  background-color: white;
}

.single-ticket-details-container {
  gap: 32px;
}

.ticket-details-table {
  width: 100%;
  border-collapse: collapse;
  margin: 16px 0;
}

.ticket-details-table td {
  padding: 8px;
  border: 1px solid #ddd;
}
.ticket-details-table tr:hover {
  background: linear-gradient(90deg, #f1edfe 0%, #f1edfe80 100%);
}

.assign-button-container-ticket-details > button {
  background-color: rgba(1, 97, 1, 0.6);
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 6px;
  outline: none;
  border: transparent;
}

.back-btn-ticket-details {
  border: 1px solid #8369fc;
  background-color: white;
  font-size: 12px;
  padding: 4px 16px;
  border-radius: 8px;
}

.issue-title-ticket-details {
  font-size: 24px;
  font-weight: bold;
}

.top-header-ticket-details {
  gap: 10px;
}

.accordion-body-text-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.accordion-body-ticket-main {
  max-height: 600px;
  overflow-y: scroll;
}

.accordion-body-text-content > strong {
  width: 200px;
  text-align: left;
}

.accordion-body-text-content > span {
}

.ticket-details-queue-left-section {
}

.accordion-header-ticket-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 20px;
  align-items: center;
}

.mark-as-resolve-btn-ticket {
  background-color: green;
  color: white;
  outline: none;
  border: 1px solid green;
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 14px;
}

.accordion-header-ticket-pending > .accordion-button {
  /* background-color: #FF9874; */
  background-color: #ff9874;
}

.accordion-header-ticket-raised > .accordion-button {
  /* background-color: #FF9874; */
  background-color: rgb(227, 44, 44);
}

.accordion-header-ticket-resolve > .accordion-button {
  /* background-color: #589859; */
  background-color: #a2ca71;
}
.accordion-header-ticket-escalated > .accordion-button {
  /* background-color: #589859; */
  background-color: #d8a25e;
}

.common-accordion-header-ticket > .accordion-button {
  height: 30px;
  display: flex;
  align-items: center;
}

.common-accordion-header-ticket > .accordion-button:focus {
  border: none;
  box-shadow: none;
}
.confirm-btn {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  padding: 10px;
  border-radius: 4px;
  min-width: 100px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.confirm-btn-green {
  background-color: #66cc66;
}
.confirm-btn-red {
  background-color: #cc6666;
}

.conversation-notes-container-tickets {
  gap: 10px;
  flex: 1;
}

.ticket-chat-main-container-details {
  width: 100%;
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between chat bubbles */
}

.single-ticket-chat-details-container {
  padding: 10px;
  border-radius: 8px;
  color: #333;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 500px;
}

.single-ticket-chat-details-container:nth-child(odd) {
  background-color: lightblue; /* Light blue background for odd items */
}

.single-ticket-chat-details-container:nth-child(even) {
  background-color: lightgreen; /* Light gray background for even items */
}

.single-ticket-chat-details-container p {
  margin: 0;
  padding: 0 10px;
}

.single-ticket-chat-details-container p:first-child {
  font-weight: bold;
  color: #333;
}

.display-all-ticket-details-container {
  gap: 8px;
}

.single-note-for-ticket-content {
  font-size: 12px;
  background-color: #d6d5d5;
  /* background-color: #489cff; */
  padding: 8px 10px;
  border-radius: 8px;
  text-align: left;
  font-weight: 600;
}

.all-notes-of-ticket-container {
  font-weight: 600;
}

.all-notes-ticket-chat-wrapper {
  display: flex;
  gap: 12px;
}

.add-icon-notes-container {
  gap: 12px;
}

.add-icon-notes-container > p {
  font-weight: 600;
}

.add-icon-notes-container > img {
  background-color: #8369fc;
  border-radius: 50%;
}

.add-notes-container-tickets-add {
  gap: 12px;
}
.add-notes-container-tickets-add > p {
  font-weight: 600;
}

.all-filtered-tickets-notes-wrapper {
  gap: 12px;
}

.ticket-raise-modal-main-container {
  padding: 24px;
  gap: 12px;
}

.ticket-raise-modal-btn > button {
  background-color: #8369fc;
  color: white;
  border-radius: 8px;
  padding: 6px 12px;
  outline: none;
  border: 1px solid #8369fc;
}

.accordion-header-ticket-user_action {
}

.status-name-ticket-details {
  background-color: white;
  border: 1px solid #dadada;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 10px;
  color: black;
  letter-spacing: 1px;
}

.status-name-ticket-details-changes {
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 12px;
  color: black;
  letter-spacing: 1px;
  color: white;
}

.reopen-ticket-btn {
  background-color: #8369fc;
  color: white;
  border-radius: 8px;
  padding: 6px 12px;
  outline: none;
  font-size: 12px;
  letter-spacing: 1px;
  border: 1px solid #8369fc;
}

.call-user-btn {
  background-color: #6ec207;
  border: 2px solid #cc6666;
  color: white;
  font-size: 12px;
  border-radius: 8px;
  padding: 3px 10px;
  padding-top: 5px;
  letter-spacing: 0.8px;
}

.call-user-btn > span {
  margin-left: 5px;
}

.call-user-btn > span .css-20bmp1-MuiSvgIcon-root {
  /* font-size: 16px !important; */
}

.info-icon-ticket {
  color: #166184;
}

.tool-tip-text-ticket {
  gap: 2px;
}

.call-user-tool-tip {
  letter-spacing: 0.5px;
}

.ant-tooltip .ant-tooltip-inner {
  padding: 6px 8px;
}


.image-wrapper-ticket-chat{
  height: 100%;
  width: 100%;
  margin-top: 10px;

}