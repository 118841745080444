/* new */

.end-chat-modal-modified-content {
  padding: 24px;
}

.dropdown-row > * {
  flex: 1;
}

.dropdown-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.dropdown-label {
  font-size: 14px;
  margin-bottom: 5px;
}

.conversation-notes-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.conversation-notes-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.toggle-row {
  display: flex;
  margin-bottom: 20px;
}

.toggle-label {
  font-size: 14px;
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.footer--next-chat {
  margin-top: 20px;
}

.get-next-chat {
  font-size: 14px;
  font-weight: 600;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.cancel-btn,
.chat-completed-btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #333;
}

.chat-completed-btn {
  background-color: #4caf50;
  border: 1px solid #4caf50;
  color: white;
}
