.my-chats-side-nav-main-container {
  /* height: 100vh; */
  overflow-y: scroll;
  width: 300px;
  height: 100%;
  background-color: white;
  border-right: 1px solid #dadada;
}

.my-chats-main-container {
  width: 100%;
  padding: 20px;
  gap: 10px;
  width: calc(100% - 300px);

  height: calc(100% - 0px);
  /* background-color: #fafafa; */
}

.my-chats-left-container {
  width: 550px;
  height: 100%;
  gap: 16px;
}

.mychats-right-container {
  width: calc(100% - 550px);
  min-height: 500px;
  height: 100%;
}

.mychats-left-bottom-container {
  height: 100%;
  width: 100%;
}

/* table */

.table-body-common-cell {
  color: var(--Text-Base, rgba(0, 0, 0, 0.87));
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  padding: 16px;
}

.table-body-common-row {
  border-bottom: 3px solid #fafafa;
  background-color: white;
  padding: 16px;
}

.table-common-cell {
  color: var(--Main-bulma, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  padding: 16px;
  font-weight: 600;
  line-height: 20px;
}

.table-common-head-container {
  border-radius: 16px 16px 0px 0px;
  background-color: #f4f5ff;
  padding: 16px;
  text-align: left;
}

.common-table-container {
  width: 100%;
  margin-top: 8px;
  border-collapse: collapse;
}

.table-head-common-row {
  text-align: left;
}

.border-red {
  border: 1px solid red;
}

.session-closed-strip {
  background-color: rgba(232, 33, 33, 0.763);
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  border-radius: 4px;
}

.agent_metrics_container {
  position: fixed;
  top: 14px;
  left: 50%;
  transform: translate(-50%);
  width: 60%;
  z-index: 20;
}

.agent_metrics_container_box {
  border: 1px solid black;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  min-width: 150px;
}
.agent_metrics_container_box-1 {
  font-size: 16px;
  font-weight: 600;
}
