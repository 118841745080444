.dropdown-container {
   
  }
  
  /* .dropdown-label {
    display: block;
    margin-bottom: 5px;

  }
  
  .dropdown-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;

 
  }

  .dropdown-select option {
    background-color: #f8f8f8 !important;
    color: #333; 
  }
  
  .dropdown-select option:hover {
    background-color: #e0e0e0; 
  } */
 
  

  /* new styles */
  .dropdown-container {
    position: relative;
    width: 100%;
    /* margin-bottom: 16px; */
  }
  
  .dropdown-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .dropdown-input {
 
   
    cursor: pointer;
    background-color: #fff;

  }
  
  .dropdown-input > input {
    border: none;
    outline: none;
     padding: 8px; 
     font-size: 14px;
     border: 1px solid #ccc;
     border-radius: 4px;
  }
  .dropdown-input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }
  
  .dropdown-search-input {
    width: 100%;
    padding: 8px;
    font-size: 12px;
    outline: none;
    box-sizing: border-box;
    border: 1px solid #ddd;
  }
  
  .dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-item {
    padding: 8px;
    cursor: pointer;
    font-size: 12px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-item:last-child {
    border-bottom: none;
  }
  
  .dropdown-item:active {
    background-color: #007bff;
    color: white;
  }
  