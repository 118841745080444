.pagination-container-main-wrapper-new {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .pagination-container-main-wrapper-new span {
    margin: 0 5px;
    cursor: pointer;
  }
  
  .active-page-number-single {
    font-weight: bold;
    display: flex;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: var(--Medium-Purple-400, #9687fe);
    color: var(--Text---Outline-Surface, #f5f5f5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.175px;
    text-transform: capitalize;
  }
  
  .non-active-page-number-single {
    color: var(--Text---Outline-TXT---PRIMARY, #242424);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.175px;
    text-transform: capitalize;
  }
  
  .pagination-input-container {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  
  .pagination-input-container > input {
    display: flex;
    width: 48px;
    padding: 4px 25px 3px 7px;
    align-items: center;
    border-radius: var(--Interactive-border-radius---radius-i-sm, 8px);
    border: 1px solid var(--Text---Outline-Stroke, #e1e1e1);
    background: var(--Main-gohan, #fff);
    color: var(--Main-bulma, #000);
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.06px;
  }
  