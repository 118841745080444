:root {
  --user-message: #489cff;
  --white: #fff;
  --ai-message: #f5f5f5;
  --black: #000;
  --purple: #8369fc;
}

body {
  background-color: #fff;
  height: 100vh;
}

.chat-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.chat-history {
  flex: 1;
  padding: 8px;
  overflow-y: auto;
}

.chat-message {
  padding: 8px 12px;
  border-radius: 12px;
  word-wrap: break-word;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  max-width: 85%;
}

.chat-option {
  margin-bottom: 16px;
  padding: 10px;
  border-radius: 8px;
  word-wrap: break-word;
}

.user-message-parent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.ai-message-parent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.user-message-parent,
.ai-message-parent {
  margin-bottom: 16px;
  gap: 8px;
}

.user-image {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}

.user-message {
  background-color: var(--user-message);
  color: white;
}

.ai-message {
  background-color: var(--ai-message);
  color: #000;
}

.option-container,
.option-container-cta {
  border-radius: 8px;
  border: 1px #eee;
  background: #fff;
  display: flex;
 
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.option-container {
  position: relative;
  min-width: 226px;

}
.option-text {
  color: var(--user-message);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.message-text {
  text-align: left;
}

.single-option-container {
  justify-content: flex-start;
  align-items: flex-start;
}

.single-option-container p:first-child,
.order_title_text {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-transform: capitalize;
}
.text-left {
  text-align: left;
}
.partision {
  border-bottom: 1px solid #eee;
  width: 100%;
}
.product-image-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
  overflow-x: scroll;
}
.product-image {
  border-radius: 4px;
  border: 1px solid #eee;
  min-width: 56px;
  min-width: 56px;
  max-width: 56px;
  max-height: 56px;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #f9f9f9;
  border: 0px solid #ddd;
  gap: 8px;
}

.input-field {
  border: 1px solid #eee;
  border-radius: 12px;
  height: 48px;
  padding: 8px 16px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  width: 100%;
}

.send-button {
  padding: 12px;
  background-color: var(--user-message);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clear-button {
  padding: 10px 20px;
  background-color: #ff0000; /* Red color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.mt-12px {
  margin-top: 12px;
}
.mb-12px {
  margin-bottom: 12px;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.trial-order-company-name {
  color: #000;
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 16.5px !important; /* 150% */
  text-transform: capitalize !important;
}
.trial-order-name-text {
  color: #808080;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px; /* 150% */
  text-transform: capitalize;
}
.quantity-text {
  color: #666;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mt-8px {
  margin-top: 8px;
}
.mb-8px {
  margin-bottom: 8px;
}
.mb-16px {
  margin-bottom: 16px;
}
.trial-product-image-container {
  height: 75px !important;
}
.trial-product-image {
  min-height: 75px;
  min-width: 56px;
  max-height: 75px;
}
.flex-flex-start-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.right_svg_icon {
  height: 22px;
  position: absolute;
  left: -6px;
  top: -6px;
  /* display: none; */
}
.submit-feedback-main-container {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: block;
  /* visibility: hidden; */
}

.submit-feedback-container {
  width: 100%;
  height: 279px;
  opacity: 1;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 16px 16px 0px 0px;
  border: 1px #eee;
  padding: 16px 16px 32px 16px;
  background: #fff;
}
.submit-feedback-container p:first-child {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.submit-feedback-container p:nth-child(2) {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.stars-main-container {
  height: 40px;
  gap: 16px;
}

.submit-feedback-button {
  background-color: var(--user-message);
  color: var(--white);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 150% */
  text-transform: capitalize;
  width: 100%;
  height: 48px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
}
.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt-40px {
  margin-top: 40px;
}
.position-relative {
  position: relative;
}
.break-point-partision {
  background: white;
  width: fit-content;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%);
}
.breakpoint-text {
  color: #999;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.break-point-partision .dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #c2c2c2;
}

.flex-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ml-16px {
  margin-left: 16px;
}
.ml-16px {
  margin-right: 16px;
}
.mt-16px {
  margin-top: 16px;
}
.mt-24px {
  margin-top: 24px;
}

.restart-conversation {
  background-color: var(--user-message);
  border-radius: 48px;
  padding: 12px;
  color: var(--white);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.restart-conversation-text {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  align-self: stretch;
}

.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}
.feed-back-close {
  padding: 6px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0.75px 2.25px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 289px;
  right: 10px;
}

.end-chat-buttons div {
  width: 50%;
  color: var(--user-message);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px; /* 150% */
  text-transform: capitalize;
  border: 1px solid var(--user-message);
  border-radius: 24px;
  height: 48px;
}
.end-chat-buttons div:nth-child(2) {
  color: var(--white);
  background-color: var(--user-message);
}
.top-header {
  border-radius: 0px 0px 16px 16px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  height: 56px;
  padding: 16px;
}
.top-header p:nth-child(1) {
  color: var(--black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.top-header p:nth-child(2) {
  color: var(--user-message);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.image-upload-container {
  border-radius: 16px;
  border: 1px dashed var(--user-message);
  background: #e8f3ff;
  padding: 8px;
  height: 150px;
}
.image-upload-container-1 {
  border-radius: 16px;
  border: 1px solid #eee;
  background: #fff;
  padding: 8px;
  height: 104px;
}

.additional-info-heading {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.additional-info-heading-1 p {
  color: #808080;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.upload-image-text-area {
  border-radius: 16px;
  border: 1.5px solid #ddd;
  height: 150px;
  padding: 16px;
  background: #fff;
}

.image-upload-successfully {
  color: var(--Green, #52b967);
  font-feature-settings: "liga" off, "clig" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  text-transform: capitalize;
}

.image-upload-successfully-image-name {
  color: #808080;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
  word-break: break-all;
  text-align: left;
}

.order-status-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 125% */
  text-transform: capitalize;
}

.order-delivery-text {
  color: #868689;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.chat-time-stamp {
  text-align: right;
  font-size: 8px;
}
