.call-user-container {
  gap: 8px;
  padding: 24px;
}


.user-phone-number {
  border: 1px solid #dadada;
  border-radius: 6px;
  padding-right: 5px;
  display: flex;
}

.user-phone-number > input {
  border: none;
  outline: none;
  margin:3px
}

.phone-number-section {
  gap: 10px;
}

.edit-icon-call {
  border-left: 1px solid #dadada;
  padding: 5px;
}

.call-message{
   color: green;
   
}

.call-error{
    color: red;
}
