.chat-modal-main-container{
    padding: 16px;
}

.top-chats-text-container-modal{
    padding-bottom: 16px;
}


.chat-modal-main-container input:focus,
.chat-modal-main-container button:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #dadada;
}

