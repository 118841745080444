.all-chats-main-container {
  display: flex;
  gap: 16px;
}

.all-chats-left-container {
  width: calc(100% - 376px);
}

.all-chats-right-container {
  width: 360px;
  min-height: 500px;
  height: calc(100vh - 88px);
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.chat-interface-wrapper-all-chats {
  height: calc(100% - 16px);
  /* height: calc(100% - 240px); */
  overflow-y: scroll;
}

.all-chats-chat-history {
  font-size: 20px;
  font-weight: bold;
}

.wrapper-daily-chats-container {
  display: flex;
  gap: 16px;
}

.all-status-daily-chats {
  display: flex;
  gap: 16px;
  align-items: center;
  background-color: white;
  border: 1px solid #dcdfe3;

  /* border-radius: 8px; */
}

.status-single-btn-daily {
  outline: none;

}

.single-status-with-count {
  display: flex;
  gap: 4px;
  font-size: 12px;
  padding: 10px 6px;

  height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clear-all-filters-daily-chats{
  background-color: white;
  border: 1px solid #dcdfe3;
  font-size: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
}


.wrapper-daily-chats-next-line-content{
  gap: 10px;
}


#survey-date-filter-dropdown-daily-chats .dropdown-menu {
  transform: translate(100px, 0px) !important;
  width: 730px !important;
  border: none !important;
  padding: 0px !important;
}