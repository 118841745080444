.flex-flex-start-flex-start,
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-space-between,
.flex-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-flex-start-flex-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.flex-space-between-flex-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.flex-felx-end-center,
.flex-flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex {
  display: flex;
  align-items: center;
}
.flex-space-between-flex-start,
.flex-space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.mt-0px {
  margin-top: 0px !important;
}
.mt-4px {
  margin-top: 4px;
}
.p-16px {
  padding: 16px;
}
.pt-20px {
  padding-top: 20px;
}

.pt-10px {
  padding-top: 10px;
}

.pl-10px {
  padding-left: 10px;
}

.pl-8px {
  padding-left: 8px;
}

.pb-8px {
  padding-bottom: 8px;
}

.pl-20px {
  padding-left: 20px;
}
.border-red {
  border: 1px solid red !important;
}
.border-blue {
  border: 1px solid #00f;
}
.border-green {
  border: 1px solid green;
}
.position-relative {
  position: relative;
}
.width-100 {
  width: 100% !important;
}

.width-42px {
  width: 42px;
}

.height-42px {
  height: 42px;
}
.height-100 {
  height: 100% !important;
}
.width-50-10px {
  width: calc(50% - 10px);
}
.width-50 {
  width: 50% !important;
}
.height-50 {
  height: 50% !important;
}

.text-left {
  text-align: left;
}
.mt-16px {
  margin-top: 16px;
}
.mt-20px {
  margin-top: 20px;
}
.ml-2px {
  margin-left: 2px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-3 {
  margin-left: 3px;
}
.mb-12px {
  margin-bottom: 12px;
}
.mb-8,
.mb-8px {
  margin-bottom: 8px;
}

.mb-3,
.mb-3px {
  margin-bottom: 3px;
}
.mb-5px {
  margin-bottom: 5px;
}
.mb-30px {
  margin-bottom: 30px;
}
.mt-5px {
  margin-top: 5px;
}
.mb-10,
.mb-10px {
  margin-bottom: 10px;
}
.mr-20px {
  margin-right: 20px;
}

.pt-5px {
  padding-top: 5px;
}
.pt-3px {
  padding-top: 3px;
}
.mb-16px {
  margin-bottom: 16px !important;
}
.mt-30px {
  margin-top: 30px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mb-0 {
  margin-bottom: 0;
}
.pb-10px {
  padding-bottom: 10px;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.p-10px {
  padding: 10px;
}
.height-700px {
  height: 700px;
}
.height-20px {
  height: 20px;
}
.overflow-scroll {
  overflow: scroll;
}
.width-400px {
  width: 400px;
}
.width-150px {
  width: 150px;
}
.bold {
  font-weight: 900;
}
p {
  margin: 0;
  margin-bottom: 0 !important;
}
.mr-5px {
  margin-right: 5px;
}
.ml-5px {
  margin-left: 5px;
}
.width-200px {
  width: 200px;
}
.width-250px {
  width: 200px;
}
.p-20px {
  padding: 20px;
}
.ml-10px {
  margin-left: 10px;
}
.ml-15px {
  margin-left: 15px;
}
.ml-16px {
  margin-left: 16px;
}
.ml-20px {
  margin-left: 20px;
}
.mt-10px {
  margin-top: 10px;
}
.m-0px {
  margin: 0;
}
.m-16px {
  margin: 16px;
}
.width-100px {
  width: 100px;
}
.mr-10px {
  margin-right: 10px;
}

.mr-8px {
  margin-right: 8px;
}
.ml-0px {
  margin-left: 0 !important;
}
.mt-24px {
  margin-top: 24px;
}
.mb-24px {
  margin-bottom: 24px;
}
.mr-16px {
  margin-right: 16px;
}
.mt-8px {
  margin-top: 8px;
}
.ml-8px {
  margin-left: 8px;
}
.ml-24px {
  margin-left: 24px;
}
input[type="checkbox"],
input[type="radio"] {
  accent-color: #8369fc;
}
.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.flex-flex-start-flex-start-column {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.flex-justify-center-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cursor-p {
  cursor: pointer;
}

.text-font-style {
  font-family: Inter;
  font-style: normal;
}

/* fonts size */

.font-14px {
  font-size: 14px;
}

.font-16px {
  font-size: 16px;
}

.font-24px {
  font-size: 24px;
}

.font-32px {
  font-size: 32px;
}

.font-36px {
  font-size: 36px;
}

.font-8px {
  font-size: 8px;
}

.font-10px {
  font-size: 10px;
}

.font-12px {
  font-size: 12px;
}

.font-18px {
  font-size: 18px;
}

.font-20px {
  font-size: 20px;
}

/* font-weight */

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.purple-btn,
.new_offline_campaign_build_footer_section-save {
  border-radius: 8px;
  background: var(--purple);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  border-radius: 8px;
  cursor: pointer;
  height: 40px;
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border: none;
}
.white-btn,
.new_offline_campaign_build_footer_section-cancel {
  background: #ffffff;
  border: 1px solid var(--purple);
  height: 40px;
  color: var(--purple);
  width: 101px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  border-radius: 8px;
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

/* react dropdown styles */

.survey-date-filter .btn-primary {
  background-color: white !important;
  color: #000 !important;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px !important;
  border: 1px solid #dcdfe3 !important;
  height: 100% !important;
  box-shadow: none !important;
}

.single-status-filter {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  margin: 8px 0px;
  cursor: pointer;
}
.date-filter-calender-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  padding-bottom: 16px;
}
.date-filter-calender-container > div {
  box-shadow: none !important;
}

.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:focus {
  box-shadow: none !important;
}

.survey-date-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100% !important;
  height: 100%;
}

.survey-date-filter .btn-primary {
  background-color: white !important;
  color: #000 !important;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px !important;
  border: 1px solid #dcdfe3 !important;
  height: 100% !important;
}

.survey-date-filter .dropdown-toggle {
  padding: 8px 24px;
}
.survey-date-filter .dropdown-toggle::after {
  display: none !important;
}

#survey-date-filter-dropdown .dropdown-menu {
  transform: translate(-200px, 0px) !important;
  width: 730px !important;
  border: none !important;
  padding: 0px !important;
}

.survey-date-filter .dropdown-menu .Day-filled-242,
.survey-date-filter .dropdown-menu .Day-filled-515 {
  background-color: #2c66d7 !important;
}

.survey-date-filter .dropdown-menu .Day-highlighted-243,
.survey-date-filter .dropdown-menu .Day-highlighted-516 {
  background: rgba(217, 217, 217, 0.36) !important;
}

/* end */

/* toggle */

.toggle-row {
  display: flex;
  margin-bottom: 20px;
}

.toggle-label {
  font-size: 14px;
  margin-right: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(14px);
}
.custom-toast-container {
  background-color: #4caf50 !important;
}
.custom-toast-container button {
  color: white;
}

.error-message {
  color: red;
  font-size: 12px;
}

.new_campaign_build_content_section_form_container input {
  background: #fff;
  border-radius: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
  line-height: 24px;
  outline: none;
  padding-left: 10px;
}
.static-border {
  border: 1px solid #cdd1dc;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.all-chats-filter-alignment {
  text-wrap: nowrap;
  padding-left: 5px;
  padding-right: 5px;
}

#survey-date-filter-dropdown-daily-chats .dropdown-menu {
  transform: translate(100px, 0px) !important;
  width: 730px !important;
  border: none !important;
  padding: 0px !important;
}
.rendered-html {
  line-height: 1.4; /* Adjust to suit your needs */
}

.rendered-html br {
  line-height: normal;
  margin: 0;
  display: none;
  border: 1px solid red;
}
.offsite-ad-modal-main-container .modal-content {
  height: 630px;
  min-width: 1200px !important;
  padding: 24px;
}
.offsite-ad-modal-main-container-heading {
  color: #1c1f37;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.offsite-ad-modal-main-container-header {
  border-bottom: 1px solid #dadada;
  padding-bottom: 3px;
}
.sortStateSectionHideMenu {
  padding: 5px !important;
  padding-bottom: 13px !important;
  border: none !important;
  background: #fff !important;
  border-radius: 4px !important;
  overflow: hidden;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
}
.dot-filter {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #8369fc;
}
