.are-you-sure-confirm-wrapper {
  gap: 24px;
  padding-top: 5px;
}

.btn-confirm-send-cancel-modal {
  gap: 12px;
}

.btn-confirm-send-cancel-modal :nth-child(2) {
  background-color: #8369fc;
  outline: none;
  border: 1px solid #8369fc;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 14px;
}

.btn-confirm-send-cancel-modal :first-child {
  background-color: white;
  outline: none;
  border: none;
  color: #8369fc;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  border-radius: 8px;
  font-size: 14px;

  padding: 6px 12px;
  border: 1px solid #8369fc;
}
