.modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.template-modal-conatiner .modal-content {
  min-width: 1080px;
  min-height: 600px;
  height: 600px;
}
.templates-modal {
  padding: 20px;
}

.templates-search-bar {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
}

.templates-list {
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-y: scroll;
  height: calc(100% - 108px);
}

.template-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

.template-item:hover {
  background: #8369fc60;
  /* color: white; */
}

.template-item:last-child {
  border-bottom: none;
}

/* .template-item:hover {
    background-color: #f1f1f1;
  }
   */
.selected {
  background-color: #8369fc;
  color: white;
}

.no-results {
  padding: 10px;
  font-size: 16px;
  text-align: center;
  color: #999;
}

.templates-modal-top-container {
  padding-bottom: 16px;
}

.dot-status-templates{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
