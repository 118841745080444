.image-preview-modal-container {
}

.wrapper-image-preview {
  gap: 40px;
  padding: 14px;

}

.cross-icon-image {
  display: flex;
  justify-content: flex-end;
 
}

.image-preview-modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
