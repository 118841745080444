.my-toast-container .Toastify__toast-theme--colored {
    background-color: #8369fc !important;
    border-radius: 6.857px;
    box-shadow: 0px 1.7142856121063232px 3.4285712242126465px 0px
      rgba(0, 0, 0, 0.1);
    color: #fff;
    margin-bottom: 4rem;
    font-family: Inter !important;
    /* min-width: 375.571px;
    min-height: 95.143px; */
    display: flex;
    justify-content: flex-start;
    
  }
  
  .my-toast-body p:nth-child(1) {
    color: #fff;
    font-family: Inter;
    font-size: 13.714px;
    font-style: normal;
    font-weight: 600;
    line-height: 20.571px;
  }
  
  .my-toast-body p{
    color: #eee;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .my-toast-body {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
  }
  
  /* .my-toast-container-body{
      border: 1px solid red;
  } */
  