.lg-modal-admin .modal-dialog-centered {
  min-width: 1080px !important;
}

.create-ticket-container {
  height: 700px;
  padding: 24px;
}
.side-heading {
  font-weight: 500;
  font-size: 16px;
}
.conversation-notes-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}
.recomendation-container {
  border: 1px solid #ddd;
  background: #fff;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: scroll;
}
.recomendation-strip {
  padding: 5px 10px;
  transition: color 200ms;
}
.select-recomendation-strip {
  background-color: #8639fc50;
  font-weight: 600;
}
.recomendation-strip:hover {
  background-color: #8639fc50;
  font-weight: 600;
}
.right-content {
  width: calc(100% - 200px);
  position: relative;
}
.material-ui-select .MuiOutlinedInput-root {
  height: 100%;
}
.material-ui-select .MuiInputBase-input {
  height: 100% !important;
  padding: 0px 10px;
  position: relative;
  top: 9px;
  background: white !important;
}
.material-ui-select input {
  height: 100%;
}
.material-ui-select #label {
  top: -7px;
}

.material-ui-select .MuiInputLabel-shrink {
  top: 0px !important;
}

.issue-summary {
  resize: none;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}
.brand-search-term-value-text {
  background-color: #eae8ff;
  border-radius: 30px;
  color: #1c1b1f;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.14px;
  line-height: normal;
  min-width: 110px;
  padding: 5px 10px;
}
.recomendation-strip-value {
  color: #1c1b1f;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.14px;
  line-height: normal;
}
