.empty-list-container {
  display: flex;
  padding-bottom: 37px;
  flex-direction: column;
  align-items: center;
  border-radius: 0px 0px 16px 16px;
  background: #fff;
}

.empty-list-img {
  width: 265px;
  height: 176px;
  flex-shrink: 0;
}

.empty-list-message {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 260px;
  text-align: center;
}

.finance-table {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.finance-table-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 16px;
}

.finance-table-center-container {
  min-width: 53.04vw;
  border-radius: 16px 16px 0px 0px;
}

.table-row-body .MuiTableCell-body:nth-child(2) {
  box-shadow: none !important;
}

.error-finance-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reject-info-table-finance {
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  gap: 3px;
  border-radius: 8px;
  background: #fdf0f0;
}

.reject-info-text-request {
  color: #de0101;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
}
