.chat-bot-mobile-main {
  background: white;
  border-radius: 4px;
  padding: 10px;
  background-position: -470px;
  background-size: cover;
  background-repeat: no-repeat;
}

.chattting-container {
  height: calc(100% - 124px);
  overflow-y: scroll;
}

.message-container {
  margin-top: 10px;
  width: 100%;
}
.box-shadow {
  box-shadow: -3px -3px 2px rgba(0, 0, 0, 0.6);
}
.chat-buttons {
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 10px;
  /* height: 40px; */
  border-radius: 4px;
  cursor: pointer;
}
.send-button-chat {
  background: linear-gradient(135deg, #4caf50 0%, #81c784 100%);
}
.send-button-chat:hover {
  background: linear-gradient(135deg, #43a047 0%, #66bb6a 100%);
}
.send-button-chat:active {
  background: linear-gradient(135deg, #388e3c 0%, #4caf50 100%);
}
.clear-button-chat {
  background: linear-gradient(135deg, #f06292 0%, #e91e63 100%);
}
.clear-button-chat:hover {
  background: linear-gradient(135deg, #ec407a 0%, #d81b60 100%);
}

.clear-button-chat:active {
  background: linear-gradient(135deg, #c2185b 0%, #e91e63 100%);
}

.sending-message {
  background: linear-gradient(135deg, #a8e063 0%, #56ab2f 100%);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px;
  padding-top: 3px;
  border-radius: 4px;
  position: relative;
}
.sending-message::after {
  content: "";
  position: absolute;
  right: -10px;
  top: 4px;
  width: 0;
  height: 0;
  border-left: 10px solid #56ab2f;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
.recive-message {
  /* background-color: linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%); */
  background: linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px;
  padding-top: 3px;
  border-radius: 4px;
  position: relative;
  /* left: 10px; */
}

.recive-message::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 4px;
  width: 0;
  height: 0;
  border-right: 10px solid #a1c4fd;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
.condtional-expression-select {
  height: 40px;
  padding: 12px 8px;
  width: 100%;
  box-shadow: 0px 0px 0px 1.007px #cdd1dc;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.static-border {
  min-height: 40px;
  border-radius: 4px;
  outline: none;
  resize: none;
  border: 1px solid #cdd1dc;
}

.color-0F1628 {
  color: #0f1628;
}

.sender-name-chatbot {
  font-weight: bold;
  font-size: 10px;
  text-wrap: nowrap;
}

.chat-time {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 10px;
}

.top-widgets-text-chatbot {
  display: flex;
  gap: 10px;
}
.ai-assistance-box {
  border: 1px solid #8369fc;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
}

.pop-over-content {
  padding: 10px;
  border-radius: 4px;
  min-width: 200px;
}
.pop-over-content p,
.hover-content {
  width: 100%;
  font-size: 12px;
  padding: 5px 5px;
  cursor: pointer;
  transition: all 500ms;
}
.pop-over-content p:hover,
.hover-content:hover {
  background-color: #8369fc60;
  border-radius: 4px;
}

.single-widget-chat {
  border: 1px solid #8369fc;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
}

/* new styles */
.user-deatils-container {
  height: 60px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #eee;
}

.user-name {
  font-size: 16px;
  font-weight: 600;
}

.user-time-duration {
  color: rgba(84, 41, 41, 0.732);
  font-size: 12px;
}
.user-time {
  font-size: 12px;
}

.recomendation-template-container {
  border: 1px solid #cdd1dc;
  max-height: 400px;
  overflow: hidden;
  width: calc(100% - 20px);
  position: absolute;
  left: 10px;
  bottom: 128px;
  background: white;
  border-radius: 4px 4px 0px 0px;
  padding: 10px;
  transition: all 500ms;
}
.fade-in {
  display: block;
}

.fade-out {
  display: none;
}
.recomendation-name-container {
  background-color: #a1c4fd;
  padding: 10px;
  text-align: left;
  font-weight: 600;
  font-size: 18px;
}

.recomendation-template-list {
  max-height: 340px;
  overflow-y: scroll;
}
.recomendation-single-template {
  padding: 10px;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  border-bottom: 1px solid #cdd1dc;
  transition: all 500ms;
}
.recomendation-single-template:last-child {
  border-bottom: none !important;
}
.recomendation-single-template:hover {
  background-color: #8369fc60;
}

.disabled-link {
  pointer-events: none;
}

.scroll-down-arrow {
  padding: 10px;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  box-shadow: 2px 2px 2px #0005;
  position: absolute;
  background-color: white;
}

.react-quil-container-custom .ql-container {
  height: calc(100% - 44px);
}

.react-quil-container-custom .ql-toolbar {
  display: flex;
}

.closed-tickets-ai{
  background-color: #388e3c;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 10px;
  color: white;
}

.open-tickets-ai{
  background-color: orange;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 10px;
  color: white;
}
